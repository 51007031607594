import React, { createContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { getUserStart, setHeaderMsg } from "./reducer"
import { useLocation, useNavigate } from "react-router-dom"
import Loader from "../Shared/LoaderV1"
import { FaBars } from "react-icons/fa"
import { AiFillHome } from "react-icons/ai"
import { AiOutlineClose as CloseIcon } from "react-icons/ai"
import Home from "../Home"
import Util from "../../helper/Util"
import { isDesktop } from "react-device-detect"
import { Translate } from "../../localization"
import { Dropdown, DropdownButton } from "react-bootstrap"
import { appLanguages } from "../../config/general.config"
import { isStaging } from "../Home/Gamelist"

export const AuthContext = createContext()

/**
 * Auth component handles the authentication and user information.
 *
 * @component
 * @param {Object} props - The component props.
 * @returns {JSX.Element} - The rendered component.
 */
const Auth = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const { userData, headerMessage, messageStatus } = useSelector((state) => state.userInfo)
  const { betOne, betTwo } = useSelector((state) => state.betPlace)

  const [toggle, setToggle] = useState(false)
  const [queryParams, setQueryParams] = useState(null)
  const [isLoading, setLoading] = useState(false)

  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("language") || "en")

  const handleLanguageChange = (value) => () => {
    setSelectedLanguage(value)
    Translate.setLanguage(value)
    localStorage.setItem("language", value)
  }

  const setLoadingValue = (value) => {
    setLoading(value)
  }

  const handleToggle = () => {
    let leftId = document.getElementById("leftId")
    let gameId = document.getElementById("gameId")

    if (!leftId || !gameId) {
      if (document.body.offsetWidth <= 575) setToggle(true)
      return null
    }

    if (toggle) {
      gameId.style.width = "100%"
      setToggle(false)
      if (document.body.offsetWidth <= 575) {
        leftId.style.width = "90%"
      } else {
        leftId.style.width = "0%"
      }
    } else {
      if (document.body.offsetWidth <= 575) {
        leftId.style.width = "0%"
      } else {
        leftId.style.width = "25%"
        gameId.style.width = "75%"
      }
      setToggle(true)
    }
  }

  const isAnyActiveBet = () => {
    return Boolean(betOne.info || betTwo.info)
  }

  const goToHome = () => {
    if (userData) {
      if (queryParams?.game) {
        if (isAnyActiveBet() || Util.checkIsNextBet()) return null

        if (userData?.username !== "crashentplayer") {
          window.location.href = userData.return_url
        } else {
          let searchQuery = `?hash=${queryParams.userHash}&token=${queryParams.token}`
          setQueryParams({ ...queryParams, game: null })
          navigate({
            pathname: "/",
            search: searchQuery
          })
        }
      } else if (window.location.origin != "https://demo.crashent.com") {
        window.parent.location.href = userData.return_url
      }
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const demoHash = "ff6be1ea-5157-4d6f-88b3-85d79f377eaa"
    const demoToken =
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJmZjZiZTFlYS01MTU3LTRkNmYtODhiMy04NWQ3OWYzNzdlYWEiLCJpYXQiOjE3MzE1OTEzNDAsIm5iZiI6MTczMTU5MTM0MCwianRpIjoiZWNkMGY2ZjMtMjhjNC00ZmQ3LTk5OTUtNThhZGM4YjBhYjRjIiwiZXhwIjoxNzMzMzE5MzQwLCJ0eXBlIjoiYWNjZXNzIiwiZnJlc2giOmZhbHNlfQ.sVFBBCxHSJUp_D7mrID7e2_C-HJj4d237nJnnGctzSQ"
    const demoStr = `hash=${demoHash}&token=${demoToken}`
    let userHash = params.get("hash")
    let token = params.get("token")
    const game = params.get("game")
    if (!userHash || !token) {
      navigate({
        pathname: "/",
        search: demoStr
      })
      userHash = demoHash
      token = demoToken
    }
    Translate.setLanguage(selectedLanguage)
    dispatch(getUserStart({ userHash, token }))
    setQueryParams({ userHash, token, game, queryText: params })
    handleToggle()
    setLoadingValue(true)
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const userHash = params.get("hash")
    const token = params.get("token")
    const game = params.get("game")
    setQueryParams({ userHash, token, game })
  }, [location])

  useEffect(() => {
    const header = document.getElementById("header")
    if (headerMessage && header) {
      header.classList.add("animation")
      setTimeout(
        () => {
          dispatch(setHeaderMsg({}))
          header.classList.remove("animation")
        },
        5000,
        true
      )
    }
  }, [headerMessage])

  const headerClass = queryParams?.game ? `app_header_${queryParams.game}` : "app_header"

  return (
    <AuthContext.Provider
      value={{
        setLoading: setLoadingValue,
        isAssetLoading: isLoading
      }}
    >
      {(!userData || isLoading) && <Loader />}
      <>
        <header id="header" className={headerClass}>
          <div className={`amoutn_flash face ${messageStatus}`}>
            <h5>{headerMessage}</h5>
          </div>
          <div className="user-info face">
            {!isDesktop && queryParams?.game && (
              <div className="toggle_btn" onClick={handleToggle}>
                {toggle ? <FaBars /> : <CloseIcon className="close-icon" />}
              </div>
            )}
            {!queryParams?.game && (
              <DropdownButton id="dropdown-basic-button" title={selectedLanguage?.toUpperCase()}>
                {appLanguages.map((lan) => {
                  return (
                    <Dropdown.Item key={`lang-${lan}`} onClick={handleLanguageChange(lan)} active={selectedLanguage == lan} eventKey={lan}>
                      {lan?.toUpperCase()}
                    </Dropdown.Item>
                  )
                })}
              </DropdownButton>
            )}
            {/* <div className='username_wrap'>
                                <span>{Translate.user} <span className='d-none d-lg-block'>:</span></span>
                                <span>{userData?.username}</span>
                            </div> */}
            {userData && (
              <div className="username_wrap">
                {/* <span>{Translate.balance} <span className='d-none d-lg-block'>:</span></span> */}
                <span>{userData?.username || ""}</span>
                <span>{Util.getFormattedCurrency(userData?.balance, userData?.currency) || ""}</span>
              </div>
            )}
            <div className="home_btn">
              <AiFillHome onClick={goToHome} />
            </div>
          </div>
        </header>
        <React.Fragment children={props.children} />
      </>
    </AuthContext.Provider>
  )
}

Auth.propTypes = {
  setUser: PropTypes.func,
  getFavorites: PropTypes.func,
  language: PropTypes.string
}

export default Auth
