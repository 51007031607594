const data = {
  en: "IN",
  user: "Pengguna",
  amount: "Jumlah",
  autoCashout: "Auto Cashout",
  autoCashoutTitle: "Pengaturan Auto Cashout",
  autoPlay: "Autoplay",
  autoPlayTitle: "Pengaturan Autoplay",
  autoPlayStart: "Mulai Autoplay",
  autoPlayRoundText: "Pilih Jumlah Autobet Anda",
  balance: "Keseimbangan",
  blastedText: "Jahanam",
  bombBlastPreStartText1: "Bom terpeleset",
  bombBlastPreStartText2: "Bisa meledak kapan saja",
  betTypeManual: "Bertaruh",
  betTypeAuto: "Mobil",
  betNow: "Bertaruh sekarang",
  betOnNextRound: "Bertaruh di babak berikutnya",
  createdAt: "Jatuh di",
  cancel: "Membatalkan",
  cashedOut: "Diuangkan",
  cashOut: "Kas keluar",
  crashedText: "jatuh",
  close: "Menutup",
  counter: "Menangkal",
  details: "Detail",
  disable: "Cacat",
  effectiveNextRound: "Efektif dari babak berikutnya",
  enterStake: "Masuk saham",
  errorBettingDisabled: "Taruhan dinonaktifkan, hubungi admin",
  gameBombBlast: "Ledakan bom",
  gameRocketBlast: "Ledakan roket",
  hashGame: "Hash Game",
  hash: "Hash",
  id: "PENGENAL",
  lastWin: "Kemenangan terakhir",
  maxStake: "Taruhan maks",
  minStake: "Min saham",
  nextRoundIn: "Babak berikutnya",
  off: "MATI",
  recentResults: "Hasil terbaru",
  rocketBlastPreText1: "Siap lepas landas",
  roundGame: "Putaran pertandingan",
  rules: "Aturan",
  salt: "Garam",
  stopIf: "Berhenti jika",
  transactions: "Transaksi",
  type: "Jenis",
  update: "Memperbarui",
  value: "Nilai",
  waitForNext: "Tunggu selanjutnya",
  warnInvalidStake: "Saham tidak valid",
  warnAutoPlayInvalidStake: "AUTOPLAY: Taruhan tidak valid",
  win: "MENANG"
}

export default data
