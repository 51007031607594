import { createSlice, current } from "@reduxjs/toolkit"

/**
 * Redux slice for managing user information in the authentication module.
 *
 * @typedef {Object} UserInfoState
 * @property {Object|null} userData - The user data.
 * @property {boolean} userLoading - Indicates if the user data is being loaded.
 * @property {string|null} headerMessage - The header message.
 * @property {string|null} messageStatus - The status of the message.
 */

/**
 * Redux slice for managing user information in the authentication module.
 *
 * @type {import("@reduxjs/toolkit").Slice<UserInfoState>}
 */
export const userInfo = createSlice({
  name: "userInfo",
  initialState: {
    userData: null,
    userLoading: false,
    headerMessage: null,
    messageStatus: null
  },
  reducers: {
    /**
     * Action to start fetching user data.
     *
     * @param {UserInfoState} state - The current state.
     * @param {import("@reduxjs/toolkit").PayloadAction} action - The action object.
     * @returns {UserInfoState} The updated state.
     */
    getUserStart: (state, action) => {
      return {
        ...state,
        userLoading: true
      }
    },

    /**
     * Action to handle successful user data retrieval.
     *
     * @param {UserInfoState} state - The current state.
     * @param {import("@reduxjs/toolkit").PayloadAction} action - The action object.
     * @returns {UserInfoState} The updated state.
     */
    getUserSuccess: (state, action) => {
      return {
        ...state,
        userData: action.payload,
        userLoading: false
      }
    },

    /**
     * Action to handle error in user data retrieval.
     *
     * @param {UserInfoState} state - The current state.
     * @param {import("@reduxjs/toolkit").PayloadAction} action - The action object.
     * @returns {UserInfoState} The updated state.
     */
    getUserError: (state, action) => {
      return {
        ...state,
        userLoading: false
      }
    },

    /**
     * Action to update user balance.
     *
     * @param {UserInfoState} state - The current state.
     * @param {import("@reduxjs/toolkit").PayloadAction} action - The action object.
     * @returns {UserInfoState} The updated state.
     */
    updateUserBalance: (state, action) => {
      const { userData } = current(state)
      if (!userData) return current(state)

      const { profit } = userData
      const { isDoubleBetPlaced, profit: newProfit, balance, margin } = action.payload
      let updatedProfit = Number(newProfit)

      if (isDoubleBetPlaced && profit > newProfit) updatedProfit = profit

      return {
        ...state,
        userData: {
          ...userData,
          balance,
          profit: updatedProfit,
          margin
        }
      }
    },

    /**
     * Action to set the header message and status.
     *
     * @param {UserInfoState} state - The current state.
     * @param {import("@reduxjs/toolkit").PayloadAction} action - The action object.
     * @returns {UserInfoState} The updated state.
     */
    setHeaderMsg: (state, action) => {
      return {
        ...state,
        headerMessage: action.payload.message,
        messageStatus: action.payload.status
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const { getUserStart, getUserSuccess, getUserError, updateUserBalance, setHeaderMsg } = userInfo.actions

export default userInfo.reducer
