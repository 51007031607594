import LocalizedStrings from "react-localization"
import ar from "./ar.js"
import de from "./de.js"
import en from "./en.js"
import fr from "./fr.js"
import IN from "./in.js"
import nl from "./nl.js"
import ru from "./ru.js"
import tr from "./tr.js"

export const Translate = new LocalizedStrings({
  ar: ar,
  de: de,
  en: en,
  fr: fr,
  in: IN,
  nl: nl,
  ru: ru,
  tr: tr
})
