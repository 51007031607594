import axios from "axios"
import { isEqual } from "lodash"
import qs from "qs"

const defaultConfig = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded"
  }
}

const bodyFormatter = (data, config) => {
  // if (false && isEqual(config, defaultConfig)) {
  //   return qs.stringify(data)
  // }
  return data
}

export const getData = ({ url, params, cancelToken }) => {
  const options = {
    method: "GET",
    params,
    url,
    cancelToken
  }
  return axios(options)
}

export const postData = ({ url, body, params }, config = defaultConfig) => axios.post(url, bodyFormatter(body, config), { params: params }, config)

export const putData = ({ url, body }, config = defaultConfig) => axios.put(url, bodyFormatter(body, config), config)

export const patchData = ({ url, body }, config = defaultConfig) => axios.patch(url, bodyFormatter(body, config), config)

export const deleteData = (url, data) => axios.delete(url, { data })
