import React, { Suspense, useEffect } from "react"
// import { API } from '@aws-amplify/api'
import * as AWS from "aws-sdk"
import { Amplify } from "aws-amplify"
import Loader from "./components/Shared/LoaderV1"
import Routes from "./Routes"
import { useRollbar } from "@rollbar/react"
import { useSelector } from "react-redux"
import { defaultStagingRoom } from "./config/general.config"

let awsmobile = {
  aws_appsync_graphqlEndpoint: "https://fct6xdumfvflhh4ptiz4ymcqay.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-ydo4dihuzraetgy3qqizq24qaa"
}

AWS.config.update({
  region: "us-east-1",
  endpoint: "dynamodb.us-east-1.amazonaws.com",
  accessKeyId: "AKIA4L2J5AVUIVYPUROR",
  secretAccessKey: "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"
})
export const dynamoClient = new AWS.DynamoDB.DocumentClient()

function App() {
  const rollbar = useRollbar()
  const { userData } = useSelector((state) => state.userInfo)

  useEffect(() => {
    let data = localStorage.getItem("reload_data")
    if (data) {
      data = JSON.parse(data)
      rollbar.error(`Before Refresh: ${data?.username}`, JSON.stringify(data))
      localStorage.setItem("reload_data", "")
    }
  }, [])

  useEffect(() => {
    if (userData?.room_id) {
      Amplify.configure(awsmobile)
    }
  }, [userData])

  return (
    <div className="main-wrap">
      <Suspense fallback={<Loader />}>
        <Routes />
      </Suspense>
    </div>
  )
}

export default App
