const data = {
  en: "DE",
  user: "Benutzer",
  amount: "Menge",
  autoCashout: "Auto -Auszahlung",
  autoCashoutTitle: "Autohout -Einstellungen automatisch",
  autoPlay: "Automatisches Abspielen",
  autoPlayTitle: "Autoplay -Einstellungen",
  autoPlayStart: "Autoplay starten",
  autoPlayRoundText: "Wählen Sie Ihre Anzahl von Autobet",
  balance: "Gleichgewicht",
  blastedText: "Gesprengt",
  bombBlastPreStartText1: "Bomb rutschte weg",
  bombBlastPreStartText2: "Kann jederzeit sprengen",
  betTypeManual: "Wette",
  betTypeAuto: "Auto",
  betNow: "Wette jetzt",
  betOnNextRound: "Wette auf die nächste Runde",
  createdAt: "Absturz bei",
  cancel: "Stornieren",
  cashedOut: "Ausgezahlt",
  cashOut: "Auszahlen",
  crashedText: "abgestürzt",
  close: "Schließen",
  counter: "Schalter",
  details: "Einzelheiten",
  disable: "Deaktivieren",
  effectiveNextRound: "Wirksam von der nächsten Runde",
  enterStake: "Eingeben",
  errorBettingDisabled: "Wetten deaktiviert, wenden Sie sich an Administrator",
  gameBombBlast: "Bombenexplosion",
  gameRocketBlast: "Raketenexplosion",
  hashGame: "Game Hash",
  hash: "Hash",
  id: "AUSWEIS",
  lastWin: "Letzter Sieg",
  maxStake: "Maximaler Einsatz",
  minStake: "Min -Pfahl",
  nextRoundIn: "Nächste Runde",
  off: "AUS",
  recentResults: "Jüngste Ergebnisse",
  rocketBlastPreText1: "Fertig zum Abheben",
  roundGame: "Spielrunde",
  rules: "Regeln",
  salt: "Salz",
  stopIf: "Stoppen Sie wenn",
  transactions: "Transaktionen",
  type: "Typ",
  update: "Aktualisieren",
  value: "Wert",
  waitForNext: "Warten Sie als nächstes",
  warnInvalidStake: "Ungültiger Anteil",
  warnAutoPlayInvalidStake: "Autoplay: Ungültiger Anteil",
  win: "GEWINNEN",
  confirmText: "Bitte bestätigen Sie, dass Sie ein rechtliches Alter haben, um Online -Casino -Spiele zu spielen.",
  yes: "Ja, ich bin über 18 Jahre alt",
  no: "Nein, bin ich nicht"
}

export default data
