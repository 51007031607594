import React, { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { gameConfig, gamesCategory } from "../../config/game.config"
import { useSelector } from "react-redux"
import AgeVerificationModal from "../Shared/AgeVerification"

export const isStaging = (user) => {
  if (user?.return_url.indexOf("dev.bet2y.co") > -1) return true
  return false
}

/**
 * Renders a list of games based on the provided category.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.category - The category of games to display.
 * @returns {JSX.Element} The rendered Gamelist component.
 */
const Gamelist = (props) => {
  const { category } = props
  const navigate = useNavigate()
  const { userData } = useSelector((state) => state.userInfo)
  const [openVerification, setOpenVerification] = useState(false)
  const [gameId, setGameId] = useState(null)

  const location = useLocation()

  const onGameClick = (id) => {
    let { search } = location
    const params = new URLSearchParams(search)
    const game = params.get("game")

    if (!game || game != id) {
      search = `${search}&game=${id}`
      navigate({
        pathname: `/game/${id}`,
        search
      })
    }
  }

  const openAgeVerification = (id) => () => {
    const isAgeVerified = sessionStorage.getItem("isAgeVerified")
    if (isAgeVerified) {
      onGameClick(id)
    } else {
      setOpenVerification(true)
      setGameId(id)
    }
  }

  return (
    <div className="gamelist-main">
      {Object.values(gameConfig)?.map((game) => {
        if (category != gamesCategory.all.value && game.category != category) return null
        if (game.isCommon && !isStaging(userData)) return null
        return (
          <div className="game" key={`game-id-${game.id}-${game.isCommon}`}>
            <img src={game.image} onClick={openAgeVerification(game.id)} />
          </div>
        )
      })}
      {openVerification && <AgeVerificationModal isOpen={openVerification} handleClose={setOpenVerification} gameplayCallback={() => onGameClick(gameId)} />}
    </div>
  )
}

export default Gamelist
