export const currencyList = {
  1: {
    name: "USD",
    symbol: "$"
  },
  2: {
    name: "EUR",
    symbol: "€"
  },
  3: {
    name: "TRY",
    symbol: "₺"
  },
  4: {
    name: "RUB",
    symbol: "₽"
  },
  5: {
    name: "INR",
    symbol: "₹"
  },
  6: {
    name: "CHF",
    symbol: "₣"
  },
  7: {
    name: "MXN",
    symbol: "MX$"
  }
}

export const gameType = {
  MOBILE: "1",
  DESKTOP: "2",
  TABLET: "3"
}

export const transactionType = {
  BET: "1",
  WIN: "2",
  REFUND: "3"
}

export const transactionTypeName = {
  1: "BET",
  2: "WIN",
  3: "REFUND"
}

export const gameIntervalValue = 9 // in seconds

export const confettiTimer = 5000 // in ms

export const precisionDigit = 2

export const gameProfit = 1000
export const marginPercentage = 20

export const defaultStake = 50
export const defaultMinStake = 1
export const defaultMaxStake = 10000
export const defaultStakeInterval = 0.1
export const defaultAutoPlayRound = 0
export const defaultAutoCashoutValue = 0
export const autoPlayOffValue = 0
export const autoCashoutOffValue = 0
export const quickStakeOptions = [5, 10, 20, 50]

export const appLanguages = ["en", "fr", "de", "tr", "in", "nl", "ru"]

export const defaultStagingRoom = "1^2"

export const defaultProdRoom = "2^2"

export const isDesktopDevice = window.innerWidth > 1024

export const COIN_STAKE = [
  { label: 1, chip: "/images/gameChakkaPatti/bet-area/poker-chip/black-poker.png" },
  { label: 3, chip: "/images/gameChakkaPatti/bet-area/poker-chip/purple-poker.png" },
  { label: 5, chip: "/images/gameChakkaPatti/bet-area/poker-chip/red-poker.png" },
  { label: 10, chip: "/images/gameChakkaPatti/bet-area/poker-chip/green-poker.png" },
  { label: 15, chip: "/images/gameChakkaPatti/bet-area/poker-chip/orange-poker.png" },
  { label: 20, chip: "/images/gameChakkaPatti/bet-area/poker-chip/blue-poker.png" }
]
