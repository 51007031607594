import React, { lazy } from "react"
import { Routes, Route } from "react-router-dom"
import Auth from "../components/Auth"
import Home from "../components/Home"

const RocketBlast = lazy(() => import("../components/Games/Rocket-II"))
const RocketBlastMultiplayer = lazy(() => import("../components/Games/Rocket-Common"))
const BombBlast = lazy(() => import("../components/Games/BombBlast"))
const BombBlastMultiplayer = lazy(() => import("../components/Games/Bomb-Common"))
const JungleRunMultiplayer = lazy(() => import("../components/Games/JungleRun-Common"))
const SpinWheel = lazy(() => import("../components/Games/SpinWheel"))
function CustomRoutes(props) {
  return (
    <Auth>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/game/1" element={<RocketBlast />} />
        <Route exact path="/game/2" element={<BombBlast />} />
        <Route exact path="/game/3" element={<RocketBlastMultiplayer />} />
        <Route exact path="/game/4" element={<BombBlastMultiplayer />} />
        <Route exact path="/game/5" element={<JungleRunMultiplayer />} />
        <Route exact path="/game/6" element={<SpinWheel />} />
      </Routes>
    </Auth>
  )
}

export default CustomRoutes
