import { combineReducers } from "@reduxjs/toolkit"
import userInfo from "../components/Auth/reducer"
import betPlace from "../components/Shared/BetModule/reducer"
import { reducer as toastr } from "react-redux-toastr"

const appReducer = combineReducers({
  toastr,
  userInfo,
  betPlace
})

const rootReducer = (state, action) => {
  //   if (action.type === userLogoutStart.type) {
  //     const {responsibleGambling, games, favourites} = state
  //     state = { responsibleGambling, games, favourites}
  //     return appReducer(state, action)
  //   }
  return appReducer(state, action)
}

export default rootReducer
