import crypto from "crypto-browserify"
import fernet from "fernet"
import { Assets } from "pixi.js"
import { v4 as uuidv4 } from "uuid"
import { currencyList, precisionDigit } from "../config/general.config"

const salt = uuidv4()
const appsyncDecryptKey = new fernet.Secret("Pxu-_b1aSn06OGkLNa75XyDIgOUk9dPo5IAb8og48xc=")

const decryptKey = new fernet.Secret("Djo-_b1aSn06OGkLNa80XyDIgOUk9dPo5IAb8og48xc=")
const AudioContext = window.AudioContext || window.webkitAudioContext
const audioCtx = new AudioContext()
class Util {
  static sleep(delay) {
    var start = new Date().getTime()
    while (new Date().getTime() < start + delay);
  }

  static createSalt = () => {
    let result = ""
    let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    let charactersLength = characters.length
    for (let i = 0; i < 16; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  static saltHash = (hash) => {
    return crypto.createHmac("sha256", hash).update(salt).digest("hex")
  }

  static generateHash = (seed) => {
    return crypto.createHmac("sha256").update(seed).digest("hex")
  }

  static divisible = (hash, mod) => {
    let val = 0
    let o = hash.length % 4

    for (let i = o > 0 ? o - 4 : 0; i < hash.length; i += 4) {
      val = ((val << 16) + parseInt(hash.substring(i, i + 4), 16)) % mod
    }
  }

  static crashPointFromHash = (serverSeed, max, isBetPlaced) => {
    const hash = crypto.createHmac("sha256", serverSeed).update(salt).digest("hex")
    const hashSalt = this.createSalt()
    const hs = parseInt(100 / 4)
    if (this.divisible(hash, hs)) return 1

    const h = parseInt(hash.slice(0, 52 / 4), 16)
    const e = Math.pow(2, 52)
    let exponent = Math.floor((100 * e - h) / (e - h)) / 100.0

    if (isBetPlaced) exponent = max
    else {
      exponent = this.getRandomInRange(4, 15) - (this.getRandomInRange(10, 50) / 100).toFixed(precisionDigit)
    }

    const random = (this.getRandomInRange(1, 5) / 100).toFixed(precisionDigit)
    if (exponent <= 1.01) exponent = 1 + Number(random)

    exponent = Number(Number(exponent).toFixed(precisionDigit))
    // exponent = 1.1

    const finalHash = crypto.createHash("sha256", exponent).update(hashSalt).digest("hex")
    return { hash: finalHash, exponent, salt: hashSalt }
  }

  static getHashFromValues = (_value, _salt) => {
    _value = _value?.toString()
    if (!_salt) return crypto.createHash("sha256").update(_value).digest("hex")
    return crypto.createHash("sha256").update(_value).update(_salt).digest("hex")
  }

  static getCalculatedMaxExpo = (exponent, stake) => {
    let random = 20
    if (exponent > 2 && stake > 0 && stake < 5) exponent = 2
    else if (exponent > 10 && stake >= 5 && stake < 21) exponent = 10
    else if (exponent > 25 && stake >= 21 && stake < 51) exponent = 25

    let rand = 0.11
    let newE = exponent

    if (exponent > 10 && stake >= 50) {
      if (stake >= 50 && stake < 200) random = 200
      else if (stake >= 200 && stake < 500) random = 300
      else if (stake >= 500 && stake < 1500) random = 400
      else if (stake >= 1500) random = 500
    }
    rand = Number((this.getRandomInRange(1, random) / 100).toFixed(2))
    newE = Number(exponent - rand).toFixed(2)
    return Number(newE)
  }

  static getPreviousGames = (crashHash) => {
    const previousGames = []
    let gameHash = this.generateHash(crashHash)

    for (let i = 0; i < 100; i) {
      const gameResult = this.crashPointFromHash(gameHash)
      previousGames.push({ gameHash, gameResult })
      gameHash = this.generateHash(gameHash)
    }

    return previousGames
  }

  static verifyCrash = (crashHash) => {
    const gameResult = this.crashPointFromHash(crashHash)
    const previousHundredGames = this.getPreviousGames()
    return { gameResult, previousHundredGames }
  }

  static getMultiplierByTime(time) {
    // time = time / 2
    // if (time > 73.9) {
    //   return +(100).toFixed(precisionDigit)
    // }
    const value = +(Math.floor(Math.pow(2, time * 0.15) * 100) / 100).toFixed(precisionDigit)
    return value
  }

  static getFormattedCurrency(value, curr) {
    let currency = sessionStorage.getItem("currency")
    if (curr) {
      const formattedCurr = Object.values(currencyList).filter((el) => el.name.toUpperCase() == curr)[0]
      if (formattedCurr?.symbol) return `${formattedCurr.symbol} ${value?.toFixed(precisionDigit)}`
    }
    return `${currencyList[currency]?.symbol} ${value?.toFixed(precisionDigit)}`
  }

  static getRandomInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  static getRandomElementPosition = (elementInfo) => {
    const { origin, randomKey, ignoredPath } = elementInfo
    let randomPosition = this.getRandomInRange(-randomKey, randomKey) + origin
    if (ignoredPath.min < randomPosition && ignoredPath.max > randomPosition) {
      randomPosition = this.getRandomInRange(0, 1)
        ? randomPosition + 1.5 * (ignoredPath.max - randomPosition)
        : randomPosition - 1.5 * (randomPosition - ignoredPath.min)
    }
    return randomPosition
  }

  static checkCollisions(sprite1, sprite2) {
    if (!sprite1.hitArea && !sprite1.hitArea) return null

    return sprite1.hitArea.contains(sprite2.hitArea.x, sprite2.hitArea.y)
  }

  static decodeAPIResponse(encryptData, isAppSyncDecrypt) {
    const key = isAppSyncDecrypt ? appsyncDecryptKey : decryptKey
    const token = new fernet.Token({
      secret: key,
      token: encryptData,
      ttl: 0
    })
    return token.decode()
  }

  static checkIsNextBet = () => {
    return Boolean(sessionStorage.getItem("betOnNextRound"))
  }

  static loadSpriteAssets = async ({ asset, setter, sheet }) => {
    if (!sheet) sheet = await Assets.load(asset)
    const { textures, linkedSheets } = sheet
    let textureNew = Object.values(textures)
    if (linkedSheets.length) {
      linkedSheets.forEach((el) => {
        const { textures } = el
        textureNew.push(...Object.values(textures))
      })
    }
    const textureArray = []
    for (const element of textureNew) {
      textureArray.push(element)
    }
    setter([...textureArray])
  }

  static assetLoader = async (asset, cb) => {
    return new Promise(async (resolve, reject) => {
      try {
        const sheet = await Assets.load(asset)
        if (cb) cb(sheet)
        resolve(sheet)
      } catch (error) {
        reject(error) // Reject the promise if an error occurs
      }
    })
  }

  static getGameInfo = () => {
    const params = new URLSearchParams(window.location.search)
    const hash = params.get("hash")
    const game = params.get("game")
    const token = params.get("token")
    return { hash, game, token }
  }

  static isValidAmount = (value) => {
    if (value == "") return true
    const amountRegex = /^\d+(\.\d{1,2})?$/
    return amountRegex.test(value)
  }

  static betRecordsFormatter = (bet_record) => {
    let result = {}
    const data = JSON.parse(bet_record)
    if (Object.keys(data).length) {
      Object.keys(data).forEach((pKey) => {
        const pData = data[pKey]
        if (Object.keys(pData).length) {
          Object.values(pData).forEach((el) => {
            const { amount, id, type, cashout_exponent, username } = el
            const playerName = username || pKey
            if (type === "cashout" && result[id]) {
              result[id] = { ...result[id], cashout_amt: amount, cashout_exponent }
            } else {
              result[id] = { ...el, player: `${playerName.toString().substr(0, 3)}*****` }
            }
          })
        }
      })
    }
    return Object.values(result)
  }

  static getAudioBuffer = async (path) => {
    let yodelBuffer
    try {
      return await window
        .fetch(path)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => audioCtx.decodeAudioData(arrayBuffer))
        .then((audioBuffer) => {
          yodelBuffer = audioBuffer
          return yodelBuffer
        })
        .catch((err) => {
          console.error(err)
          return yodelBuffer
        })
    } catch (error) {}
  }

  static getAudioBufferSource = (audioBuffer, options = {}) => {
    const source = audioCtx.createBufferSource()
    source.buffer = audioBuffer
    source.connect(audioCtx.destination)
    source.loop = options.loop || false
    source.start(options.start || 0)
    source.onended = function () {
      console.log("Your audio has finished playing")
      if (options.onended) options.onended()
    }
    console.log("start source ", source)
    return source
  }

  static formatToISOString = (date) => {
    if (date.indexOf(".000Z") == -1) {
      var str = date + ".000Z"
      str = str.split(" ").join("T")
      return str
    }
    return date
  }
}

export default Util
