import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Modal, Button } from "react-bootstrap"
import "./style.scss"
import { Translate } from "../../../localization"
import { useSelector } from "react-redux"

/**
 * AgeVerificationModal component displays a modal for age verification.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpen - Determines whether the modal is open or not.
 * @param {Function} props.handleClose - Callback function to handle modal close event.
 * @param {Function} props.gameplayCallback - Callback function to handle gameplay event.
 * @returns {JSX.Element} The AgeVerificationModal component.
 */
const AgeVerificationModal = (props) => {
  const { isOpen, handleClose, gameplayCallback } = props
  const navigate = useNavigate()
  const location = useLocation()

  const [showModal, setShowModal] = useState(null)
  const { userData } = useSelector((state) => state.userInfo)

  useEffect(
    () => {
      if (isOpen && userData?.username === "crashentplayer") {
        setShowModal(true)
      }
    },
    [userData],
    location
  )

  const goToHome = () => {
    const params = new URLSearchParams(location.search)
    const userHash = params.get("hash")
    const token = params.get("token")
    const gameId = params.get("game")
    if (userData) {
      if (gameId) {
        if (userData?.username !== "crashentplayer") {
          window.location.href = userData.return_url
        } else {
          let searchQuery = `?hash=${userHash}&token=${token}`
          navigate({
            pathname: "/",
            search: searchQuery
          })
        }
      } else if (window.location.origin != "https://demo.crashent.com") {
        window.parent.location.href = userData.return_url
      }
    }
  }

  const handleYesBtnClick = (e) => {
    sessionStorage.setItem("isAgeVerified", true)
    handleClose(false)
    if (gameplayCallback) gameplayCallback()
  }

  const handleNoBtnClick = (e) => {
    handleClose(false)
  }

  return (
    <Modal show={showModal} className="AgeVerification-modal">
      <Modal.Header>
        <Modal.Title>Safe and Responsible Play ahead!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{Translate.confirmText}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleYesBtnClick}>{Translate.yes}</Button>
        <Button onClick={handleNoBtnClick} className="disabled-consent">
          {Translate.no}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AgeVerificationModal
