import { put, takeLatest, takeEvery } from "redux-saga/effects"
import { getData, postData } from "../../../helper/axios/apiCalls"
import { routes } from "../../../config/api.config"
import {
  winBetStart,
  setWinBetError,
  setWinBetSuccess,
  getPreviousHistoryFailure,
  getPreviousHistoryStart,
  getPreviousHistorySuccess,
  getTransactionFailure,
  getTransactionStart,
  getTransactionSuccess,
  getRecentResultsFailure,
  getRecentResultsStart,
  getRecentResultsSuccess,
  placeBet,
  setPlaceBetError,
  setPlaceBetSuccess,
  setCompleteRound,
  saveCrashPointInfo
} from "./reducer"
import { toastr } from "react-redux-toastr"
import { setHeaderMsg, updateUserBalance } from "../../Auth/reducer"
import { BACKGROUND_STATUS } from "../../../config/style.config"
import Util from "../../../helper/Util"
import { precisionDigit } from "../../../config/general.config"

function* betPlaceWatcher() {
  yield takeEvery(placeBet.type, placeBetWorker)
  yield takeEvery(winBetStart.type, cashoutWorker)
  yield takeLatest(getPreviousHistoryStart.type, getGameHistoryWorker)
  yield takeLatest(getTransactionStart.type, getUserTransactionWorker)
  yield takeLatest(getRecentResultsStart.type, getUserRecentResultsWorker)
  yield takeEvery(setCompleteRound.type, setRoundCompleteWorker)
  yield takeEvery(saveCrashPointInfo.type, saveCrashInfoWorker)
}

function* placeBetWorker(action) {
  try {
    let { payload } = action
    const { id, isDoubleBetPlaced } = payload
    const betPayload = {
      ...payload.bet
    }
    const result = yield postData({ url: routes.processGameEvent, body: betPayload, params: { token: payload.token } })
    if (result && result.data) {
      const { enc_response } = result.data
      let decoded = {}
      if (enc_response) {
        // eslint-disable-next-line quotes
        let decodedData = Util.decodeAPIResponse(enc_response).replace(/'/g, '"')
        decoded = JSON.parse(decodedData)
      }
      const { balance, profit, margin } = decoded
      if (margin) yield put(updateUserBalance({ balance: Number(balance), profit: Number(profit), margin, isDoubleBetPlaced }))
      yield put(setPlaceBetSuccess({ message: result.data?.message, bet: { ...payload.bet, round_id: result.data?.round_id }, id }))
      yield put(setHeaderMsg({ message: result.data?.message, status: BACKGROUND_STATUS.success }))
    }
  } catch (e) {
    const { payload } = action
    const { id } = payload
    console.error(e)
    yield put(setHeaderMsg({ message: e.response.data.detail.message, status: BACKGROUND_STATUS.error }))
    yield put(setPlaceBetError({ message: e.response.data.detail.message, id }))
  }
}

/**
 * Handles the cashoutWorker action.
 *
 * @param {Object} action - The action object.
 * @param {Object} action.payload - The payload of the action.
 * @param {string} action.payload.token - The token for authentication.
 * @param {string} action.payload.id - The ID of the bet.
 * @param {Object} action.payload.bet - The bet object.
 * @param {number} action.payload.bet.amount - The amount of the bet.
 * @param {number} action.payload.bet.cashout_exponent - The cashout exponent of the bet.
 */
function* cashoutWorker(action) {
  try {
    const { payload } = action
    const {
      id,
      bet: { amount, cashout_exponent }
    } = payload
    const result = yield postData({ url: routes.processGameEvent, body: payload.bet, params: { token: payload.token } })
    if (result && result.data) {
      const { enc_response } = result.data
      let decoded = {}
      if (enc_response) {
        // eslint-disable-next-line quotes
        let decodedData = Util.decodeAPIResponse(enc_response).replace(/'/g, '"')
        decoded = JSON.parse(decodedData)
      }
      const lastWin = (amount * cashout_exponent)?.toFixed(precisionDigit)
      const { balance, profit, margin } = decoded
      sessionStorage.setItem("last_win", lastWin)
      if (margin) yield put(updateUserBalance({ balance: Number(balance), profit, margin }))
      yield put(setWinBetSuccess({ message: result.data?.message, id }))
      yield put(setHeaderMsg({ message: result.data?.message, status: BACKGROUND_STATUS.success }))
    }
  } catch (e) {
    const { payload } = action
    const { id } = payload
    console.error(e)
    yield put(setHeaderMsg({ message: e.response.data.detail.message, status: BACKGROUND_STATUS.error }))
    yield put(setWinBetError({ message: e.response.data.detail.message, id }))
  }
}

function* getGameHistoryWorker(action) {
  try {
    const { token, user_hash, room_id } = action.payload
    const result = yield getData({ url: routes.getUserCrashPoint, params: { token, user_hash, count: 40, room_id } })
    if (result && result.data) {
      let data = result.data.map((el) => ({ ...el, crashPoint: el.point }))
      data = data.filter((el) => el.hash && el.hash !== "string")
      yield put(getPreviousHistorySuccess(data))
    }
  } catch (e) {
    toastr.error(e.response.data.detail.message)
    console.error(e)
    yield put(getPreviousHistoryFailure(e.response.data.detail.message))
  }
}

function* getUserTransactionWorker(action) {
  try {
    const { token, user_hash } = action.payload
    const result = yield getData({ url: routes.userTransaction, params: { token, user_hash, game_id: Util.getGameInfo().game } })
    if (result && result.data) {
      yield put(getTransactionSuccess(result.data))
    }
  } catch (e) {
    toastr.error(e.response.data.detail.message)
    console.error(e)
    yield put(getTransactionFailure(e.response.data.detail.message))
  }
}

function* getUserRecentResultsWorker(action) {
  try {
    const { token, user_hash } = action.payload
    const result = yield getData({ url: routes.userResults, params: { token, user_hash, game_id: Util.getGameInfo().game } })
    if (result && result.data) {
      let data = result.data
      data = data.filter((el) => el.round_hash || el.server_seed)
      yield put(getRecentResultsSuccess(data))
    }
  } catch (e) {
    toastr.error(e.response.data.detail.message)
    console.error(e)
    yield put(getRecentResultsFailure(e.response.data.detail.message))
  }
}

function* setRoundCompleteWorker(action) {
  try {
    const { payload } = action
    const { roundId, userHash, token, point: exponent, hash, salt, callback } = payload
    const body = {
      round_id: roundId,
      user_hash: userHash,
      exponent,
      round_hash: hash,
      salt
    }
    const result = yield postData({ url: routes.roundIdComplete, body: { ...body }, params: { token: token } })
    if (callback) callback()
  } catch (e) {
    // toastr.error(e.response.data.detail.message)
    console.error(e)
  }
}

function* saveCrashInfoWorker(action) {
  try {
    const { payload } = action
    const { point, hash, userHash, token } = payload
    const body = {
      point,
      hash
    }
    const result = yield postData({ url: routes.addCrashPoint, body: { ...body }, params: { token: token, user_hash: userHash } })
  } catch (e) {
    // toastr.error(e.response.data.detail.message)
    console.error(e)
  }
}

export default betPlaceWatcher
