const data = {
  en: "RU",
  user: "Пользователь",
  amount: "Количество",
  autoCashout: "Авто Кассоут",
  autoCashoutTitle: "Автоматические настройки",
  autoPlay: "Автовоспроизведение",
  autoPlayTitle: "Настройки автопроизводства",
  autoPlayStart: "Запустить автозапрограмму",
  autoPlayRoundText: "Выберите количество автобета",
  balance: "Баланс",
  blastedText: "Взорвался",
  bombBlastPreStartText1: "Бомба ускользнула",
  bombBlastPreStartText2: "Может взорваться в любое время",
  betTypeManual: "Делать ставку",
  betTypeAuto: "Авто",
  betNow: "Ставки сейчас",
  betOnNextRound: "Ставка на следующий раунд",
  createdAt: "Разбился в",
  cancel: "Отмена",
  cashedOut: "Обналичил",
  cashOut: "Обналичить",
  crashedText: "разбился",
  close: "закрывать",
  counter: "Прилавок",
  details: "Подробности",
  disable: "Запрещать",
  effectiveNextRound: "Эффективно из следующего раунда",
  enterStake: "Введите кол",
  errorBettingDisabled: "Ставки отключены, свяжитесь с администратором",
  gameBombBlast: "Взрыв бомбы",
  gameRocketBlast: "Ракетный взрыв",
  hashGame: "Игровой хеш",
  hash: "Хэш",
  id: "ИДЕНТИФИКАТОР",
  lastWin: "Последняя победа",
  maxStake: "Макс ставки",
  minStake: "Мин ставок",
  nextRoundIn: "В следующем раунде",
  off: "ВЫКЛЮЧЕННЫЙ",
  recentResults: "Последние результаты",
  rocketBlastPreText1: "Готов снять",
  roundGame: "Игра раунд",
  rules: "Правила",
  salt: "Соль",
  stopIf: "Остановиться, если",
  transactions: "Транзакции",
  type: "Тип",
  update: "Обновлять",
  value: "Ценить",
  waitForNext: "Подождите дальше",
  warnInvalidStake: "Неверный кол",
  warnAutoPlayInvalidStake: "Автозаплят: неверный кол",
  win: "ПОБЕДИТЬ",
  confirmText: "Пожалуйста, подтвердите, что вы юридический возраст для игры в онлайн -казино.",
  yes: "Да, мне старше 18 лет",
  no: "Нет"
}

export default data
