import { put, takeLatest, delay } from "redux-saga/effects"
import { getData, postData } from "../../helper/axios/apiCalls"
import { routes } from "../../config/api.config"
import { toastr } from "react-redux-toastr"
import { getUserError, getUserStart, getUserSuccess } from "./reducer"

function* UserInfoWatcher() {
  yield takeLatest(getUserStart.type, getUserWorker)
}

/**
 * Worker function for getting user information.
 *
 * @param {Object} action - The action object containing the payload.
 * @param {string} action.payload.token - The user token.
 * @param {string} action.payload.userHash - The user hash.
 */
function* getUserWorker(action) {
  try {
    const { payload } = action
    const result = yield getData({ url: routes.getUserInfo, params: { token: payload.token, user_hash: payload.userHash } })
    if (result && result.data) {
      const user = result.data
      sessionStorage.setItem("currency", user.currency)
      // if (user.room_id == '3^3') user.is_provably_active = true
      // user.room_id = '2^2'
      yield put(getUserSuccess({ ...user }))
    }
  } catch (e) {
    toastr.error(e.response.data.detail.message)
    console.error(e)
    yield put(getUserError(e.response.data.detail.message))
  }
}

export default UserInfoWatcher
