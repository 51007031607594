import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "./assest/style/main.scss"
import "bootstrap/dist/css/bootstrap.min.css"
import store from "./redux-saga/store"
import { Provider } from "react-redux"
import ReduxToastr from "react-redux-toastr"
import { BrowserRouter } from "react-router-dom"
import { Provider as RollbarProvider, ErrorBoundary } from "@rollbar/react"

const root = ReactDOM.createRoot(document.getElementById("root"))

const rollbarConfig = {
  accessToken: "d7cdd6b421f14cb4b9e1f26a0c476f8c",
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: window.location.hostname
}

function TestError() {
  const a = null
  return a.hello()
}

root.render(
  // <React.StrictMode>
  <RollbarProvider config={rollbarConfig}>
    <ErrorBoundary>
      {/* <TestError /> */}
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        <ReduxToastr
          timeOut={2000}
          newestOnTop={true}
          preventDuplicates
          position="top-center"
          getState={(state) => state.toastr}
          transitionIn="bounceIn"
          transitionOut="bounceOut"
          removeOnHover={true}
          closeOnToastrClick
        />
      </Provider>
    </ErrorBoundary>
  </RollbarProvider>

  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
