import { createSlice } from "@reduxjs/toolkit"
import { transactionType } from "../../../config/general.config"

const initialState = {
  betPlacedSuccess: null,
  betPlacedError: null,
  betWinSuccess: null,
  betWinError: null,
  gamePreviousHistory: [],
  usersRecentResults: [],
  usersTransactions: [],
  latestWinData: null,
  betOne: {
    id: "betOne",
    info: null,
    placed: { success: null, error: null },
    win: { success: null, error: null }
  },
  betTwo: {
    id: "betTwo",
    info: null,
    placed: { success: null, error: null },
    win: { success: null, error: null }
  }
}

/**
 * Reducer for the bet placement module.
 *
 * @typedef {Object} BetPlaceState - The state object for the bet placement module.
 * @property {Object} initialState - The initial state object.
 * @property {Object} initialState.info - The initial bet information.
 * @property {Object} initialState.placed - The initial bet placement status.
 * @property {Object} initialState.win - The initial bet win status.
 * @property {Array} gamePreviousHistory - The previous history of the game.
 * @property {Array} usersTransactions - The transactions of the users.
 * @property {Array} usersRecentResults - The recent results of the users.
 * @property {Object} latestWinData - The latest win data.
 *
 * @typedef {Object} BetPayload - The payload object for bet actions.
 * @property {Object} bet - The bet information.
 * @property {string} id - The ID of the bet.
 *
 * @typedef {Object} PlacedPayload - The payload object for bet placement actions.
 * @property {Object} bet - The bet information.
 * @property {string} id - The ID of the bet.
 * @property {Object} placed - The bet placement status.
 *
 * @typedef {Object} WinPayload - The payload object for bet win actions.
 * @property {string} id - The ID of the bet.
 * @property {Object} win - The bet win status.
 *
 * @typedef {Object} GetPreviousHistoryPayload - The payload object for get previous history actions.
 * @property {Array} gamePreviousHistory - The previous history of the game.
 *
 * @typedef {Object} GetTransactionPayload - The payload object for get transaction actions.
 * @property {Array} usersTransactions - The transactions of the users.
 *
 * @typedef {Object} GetRecentResultsPayload - The payload object for get recent results actions.
 * @property {Array} usersRecentResults - The recent results of the users.
 *
 * @typedef {Object} Action - The action object.
 * @property {string} type - The type of the action.
 * @property {any} payload - The payload of the action.
 *
 * @typedef {Object} BetPlaceReducer - The reducer object for the bet placement module.
 * @property {string} name - The name of the reducer.
 * @property {Object} initialState - The initial state object.
 * @property {Function} reducers.setCurrentBetInfo - The reducer function for setting the current bet information.
 * @property {Function} reducers.placeBet - The reducer function for placing a bet.
 * @property {Function} reducers.setPlaceBetSuccess - The reducer function for setting the bet placement success status.
 * @property {Function} reducers.setPlaceBetError - The reducer function for setting the bet placement error status.
 * @property {Function} reducers.winBetStart - The reducer function for starting a bet win.
 * @property {Function} reducers.setWinBetSuccess - The reducer function for setting the bet win success status.
 * @property {Function} reducers.setWinBetError - The reducer function for setting the bet win error status.
 * @property {Function} reducers.getPreviousHistoryStart - The reducer function for starting to get previous history.
 * @property {Function} reducers.getPreviousHistorySuccess - The reducer function for setting the previous history.
 * @property {Function} reducers.getPreviousHistoryFailure - The reducer function for handling the failure to get previous history.
 * @property {Function} reducers.getTransactionStart - The reducer function for starting to get transactions.
 * @property {Function} reducers.getTransactionSuccess - The reducer function for setting the transactions.
 * @property {Function} reducers.getTransactionFailure - The reducer function for handling the failure to get transactions.
 * @property {Function} reducers.getRecentResultsStart - The reducer function for starting to get recent results.
 * @property {Function} reducers.getRecentResultsSuccess - The reducer function for setting the recent results.
 * @property {Function} reducers.getRecentResultsFailure - The reducer function for handling the failure to get recent results.
 * @property {Function} reducers.setCompleteRound - The reducer function for setting a complete round.
 * @property {Function} reducers.saveCrashPointInfo - The reducer function for saving crash point information.
 * @property {Function} reducers.resetGameRound - The reducer function for resetting the game round.
 * @property {Function} reducers.resetLatestWinData - The reducer function for resetting the latest win data.
 *
 * @type {BetPlaceReducer} - The reducer for the bet placement module.
 */
export const betPlace = createSlice({
  name: "betPlace",
  initialState: { ...initialState },
  reducers: {
    setCurrentBetInfo: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    placeBet: (state, action) => {
      const { bet, id } = action.payload
      if (!bet) return state
      return {
        ...state,
        [id]: {
          ...state[id],
          info: bet
        }
      }
    },
    setPlaceBetSuccess: (state, action) => {
      let { bet, id, placed } = action.payload
      if (!bet) return state
      if (!placed) placed = { success: true, error: null }
      return {
        ...state,
        [id]: {
          ...state[id],
          info: bet,
          placed: { ...placed }
        }
      }
    },
    setPlaceBetError: (state, action) => {
      let { id, placed } = action.payload
      if (!placed) placed = { success: null, error: true }
      return {
        ...state,
        [id]: {
          ...state[id],
          placed: { ...placed }
        }
      }
    },
    winBetStart: (state, action) => {
      const { bet, id } = action.payload
      if (!bet) return state
      return {
        ...state,
        [id]: {
          ...state[id],
          info: bet,
          win: { success: null, error: null }
        }
      }
    },
    setWinBetSuccess: (state, action) => {
      let { id, win } = action.payload
      if (!win) win = { success: true, error: null }
      const latestWinData = { amount: Number(sessionStorage.getItem("last_win")) || 0 }
      return {
        ...state,
        [id]: {
          ...state[id],
          win: { ...win }
        },
        latestWinData
      }
    },
    setWinBetError: (state, action) => {
      let { id, win } = action.payload
      if (!win) win = { success: null, error: true }
      return {
        ...state,
        [id]: {
          ...state[id],
          win: { ...win },
          info: null
        }
      }
    },
    getPreviousHistoryStart: (state, action) => {
      return {
        ...state
      }
    },
    getPreviousHistorySuccess: (state, action) => {
      return {
        ...state,
        gamePreviousHistory: action.payload
      }
    },
    getPreviousHistoryFailure: (state, action) => {
      return {
        ...state,
        gamePreviousHistory: []
      }
    },
    getTransactionStart: (state, action) => {
      return {
        ...state
      }
    },
    getTransactionSuccess: (state, action) => {
      const data = action.payload
      return {
        ...state,
        usersTransactions: data
      }
    },
    getTransactionFailure: (state, action) => {
      return {
        ...state,
        usersTransactions: []
      }
    },
    getRecentResultsStart: (state, action) => {
      return {
        ...state
      }
    },
    getRecentResultsSuccess: (state, action) => {
      return {
        ...state,
        usersRecentResults: action.payload
      }
    },
    getRecentResultsFailure: (state, action) => {
      return {
        ...state,
        usersRecentResults: []
      }
    },
    setCompleteRound: (state, action) => {
      return { ...state }
    },
    saveCrashPointInfo: (state, action) => {
      return { ...state }
    },
    resetGameRound: (state, action) => {
      return { ...initialState }
    },
    resetLatestWinData: (state, action) => {
      return { ...state, latestWinData: null }
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  placeBet,
  setPlaceBetSuccess,
  setPlaceBetError,
  winBetStart,
  setWinBetSuccess,
  setWinBetError,
  getPreviousHistoryStart,
  getPreviousHistorySuccess,
  getPreviousHistoryFailure,
  getTransactionStart,
  getTransactionSuccess,
  getTransactionFailure,
  getRecentResultsStart,
  getRecentResultsSuccess,
  getRecentResultsFailure,
  setCompleteRound,
  setCurrentBetInfo,
  saveCrashPointInfo,
  resetGameRound,
  resetLatestWinData
} = betPlace.actions

export default betPlace.reducer
