const data = {
  en: "EN",
  user: "User",
  amount: "Amount",
  autoCashout: "Auto Cashout",
  autoCashoutTitle: "Auto Cashout Settings",
  autoPlay: "Autoplay",
  autoPlayTitle: "Autoplay Settings",
  autoPlayStart: "Start Autoplay",
  autoPlayRoundText: "Choose your number of autobet",
  betting: "Betting...",
  balance: "Balance",
  blastedText: "Blasted",
  bombBlastPreStartText1: "Bomb slipped away",
  bombBlastPreStartText2: "Can Blast Anytime",
  betTypeManual: "Bet",
  betTypeAuto: "Auto",
  betNow: "Bet Now",
  betOnNextRound: "Bet on next round",
  createdAt: "Crashed at",
  cancel: "Cancel",
  cashedOut: "Cashed Out",
  crashedText: "Crashed",
  close: "Close",
  counter: "Counter",
  details: "Details",
  disable: "Disable",
  effectiveNextRound: "Effective from next round",
  enterStake: "Enter Stake",
  errorBettingDisabled: "Betting disabled, contact admin",
  gameBombBlast: "Bomb Blast",
  gameRocketBlast: "Rocket Blast",
  gameRocketBlastMO: "Rocket Blast MO",
  gameBombBlastMO: "Bomb Blast MO",
  gameBookOfCrash: "Book Of Crash",
  gameChakkaPatti: "Chakka Patti",
  spinWheel: "Spin Of Wheel",
  hashGame: "Game hash",
  hash: "Hash",
  id: "ID",
  lastWin: "Last Win",
  maxStake: "Max Stake",
  minStake: "Min Stake",
  nextRoundIn: "Next Round In",
  off: "OFF",
  recentResults: "Recent Results",
  rocketBlastPreText1: "Ready to take off",
  roundGame: "Game Round",
  rules: "Rules",
  salt: "Salt",
  stopIf: "STOP IF",
  startFromNext: "Start From Next",
  transactions: "Transactions",
  type: "Type",
  update: "Update",
  value: "Value",
  waitForNext: "Wait For Next",
  warnInvalidStake: "Invalid stake",
  warnAutoPlayInvalidStake: "Autoplay: Invalid stake",
  win: "WIN",
  betAmount: "Bet Amount",
  cashOut: "Cashout",
  playerName: "Player",
  currentGame: "Current Round",
  cashoutExponent: "Exponent",
  confirmText: "Please confirm you are of a legal age to play online casino games.",
  yes: "Yes, I'm Over 18",
  no: "No, I am not",
  totalBetAmount: "Total Stake",
  warnBetPlaced: "Bet already placed",
  placed: "Placed",
  selectAll: "Select All",
  clearAll: "Clear All",
  combinationName: "Win Combination",
  winMultiplier: "Win Multiplier",
  winAmount: "Win Amount",
  holdOnRoundRunning: "Hold on!"
}

export default data
