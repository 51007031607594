const data = {
  en: "AR",
  user: "مستخدم",
  amount: "كمية",
  autoCashout: "Auto Cashout",
  autoCashoutTitle: "إعدادات الأموال التلقائية",
  autoPlay: "تشغيل تلقائي",
  autoPlayTitle: "إعدادات اللعب التلقائي",
  autoPlayStart: "بدء تشغيل Autoplay",
  autoPlayRoundText: "اختر عدد AutoBet الخاص بك",
  balance: "توازن",
  blastedText: "انفجر",
  bombBlastPreStartText1: "قنبلة تراجعت بعيدا",
  bombBlastPreStartText2: "يمكن أن تنفجر في أي وقت",
  betTypeManual: "رهان",
  betTypeAuto: "آلي",
  betNow: "الرهان الآن",
  betOnNextRound: "الرهان على الجولة التالية",
  createdAt: "تحطمت في",
  cancel: "يلغي",
  cashedOut: "سحبت",
  cashOut: "المصروفات",
  crashedText: "تحطم",
  close: "يغلق",
  counter: "عداد",
  details: "تفاصيل",
  disable: "إبطال",
  effectiveNextRound: "فعالة من الجولة التالية",
  enterStake: "أدخل الحصة",
  errorBettingDisabled: "الرهان المعاق ، مسؤول الاتصال",
  gameBombBlast: "انفجار قنبلة",
  gameRocketBlast: "انفجار صاروخ",
  hashGame: "هاش اللعبة",
  hash: "التجزئة",
  id: "بطاقة تعريف",
  lastWin: "الفوز الأخير",
  maxStake: "حصة ماكس",
  minStake: "حصة مين",
  nextRoundIn: "الجولة القادمة في",
  off: "عن",
  recentResults: "النتائج الأخيرة",
  rocketBlastPreText1: "جاهز للإقلاع",
  roundGame: "جولة اللعبة",
  rules: "قواعد",
  salt: "ملح",
  stopIf: "توقف إذا",
  transactions: "المعاملات",
  type: "يكتب",
  update: "تحديث",
  value: "قيمة",
  waitForNext: "انتظر التالي",
  warnInvalidStake: "حصة غير صالحة",
  warnAutoPlayInvalidStake: "اللعب التلقائي: حصة غير صالحة",
  win: "يفوز"
}

export default data
