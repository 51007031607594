const data = {
  en: "FR",
  user: "Utilisateur",
  amount: "Montant",
  autoCashout: "Retrait automatique",
  autoCashoutTitle: "Paramètres de cashout automatique",
  autoPlay: "Lecture automatique",
  autoPlayTitle: "Paramètres de lecture automatique",
  autoPlayStart: "Démarrer la lecture automatique",
  autoPlayRoundText: "Choisissez votre nombre d'autobet",
  balance: "Équilibre",
  blastedText: "Foudroyé",
  bombBlastPreStartText1: "La bombe a glissé",
  bombBlastPreStartText2: "Peut faire exploser à tout moment",
  betTypeManual: "Pari",
  betTypeAuto: "Auto",
  betNow: "Parier maintenant",
  betOnNextRound: "Je parie au prochain tour",
  createdAt: "S'est écrasé à",
  cancel: "Annuler",
  cashedOut: "Encaissé",
  cashOut: "Retirer",
  crashedText: "écrasé",
  close: "Fermer",
  counter: "Comptoir",
  details: "Détails",
  disable: "Désactiver",
  effectiveNextRound: "Efficace à partir du prochain tour",
  enterStake: "Entrez le pieu",
  errorBettingDisabled: "Paris désactivé, Contact Admin",
  gameBombBlast: "Une explosion de bombe",
  gameRocketBlast: "Explosion de fusée",
  hashGame: "Hachage de jeu",
  hash: "Hacher",
  id: "IDENTIFIANT",
  lastWin: "Dernière victoire",
  maxStake: "Pieu maximal",
  minStake: "Enjeu min",
  nextRoundIn: "Prochain tour dans",
  off: "DÉSACTIVÉ",
  recentResults: "Résultats récents",
  rocketBlastPreText1: "Prêt à décoller",
  roundGame: "Match de jeu",
  rules: "Règles",
  salt: "Sel",
  stopIf: "Arrêter si",
  transactions: "Transactions",
  type: "Taper",
  update: "Mise à jour",
  value: "Valeur",
  waitForNext: "Attendez le prochain",
  warnInvalidStake: "Pieu invalide",
  warnAutoPlayInvalidStake: "AutoPlay: un pieu non valide",
  win: "GAGNER",
  confirmText: "Veuillez confirmer que vous avez un âge légal pour jouer à des jeux de casino en ligne.",
  yes: "Oui, j'ai plus de 18 ans",
  no: "Non, je ne suis pas"
}

export default data
