import { Translate } from "../localization"

const gameIdInfo = {
  rocketBlast: 1,
  bombBlast: 2,
  rocketBlastMultiplayer: 3,
  bombBlastMultiplayer: 4,
  jungleRunMultiplayer: 5,
  speenWheel: 6
}

export const gamesCategory = {
  all: {
    value: "all",
    label: "All",
    icon: "/images/category-all.png"
  },
  rocket: {
    value: "rocket",
    label: "Rocket",
    icon: "/images/category-rocket.svg"
  },
  bomb: {
    value: "Bomb",
    label: "Bomb",
    icon: "/images/category-bomb.svg"
  }
}

export const BUCKET_URL = "https://gamerulesbucket.s3.amazonaws.com"

export const gameConfig = {
  // [gameIdInfo.rocketBlast]: {
  //     id: gameIdInfo.rocketBlast,
  //     name: Translate.gameRocketBlast,
  //     image: '/images/games/rocketBlast.jpg',
  //     category: gamesCategory.rocket.value,
  //     rules: 'https://gamerulesbucket.s3.amazonaws.com/RocketBlastRules.html',
  //     production: true,
  // },
  // [gameIdInfo.bombBlast]: {
  //     id: gameIdInfo.bombBlast,
  //     name: Translate.gameBombBlast,
  //     image: '/images/games/bombblast.gif',
  //     category: gamesCategory.bomb.value,
  //     rules: 'https://gamerulesbucket.s3.amazonaws.com/BombBlastRules.html',
  //     production: true,
  // },
  [gameIdInfo.rocketBlastMultiplayer]: {
    id: gameIdInfo.rocketBlastMultiplayer,
    name: Translate.gameRocketBlastMO,
    image: "/images/games/common-rocketBlast-v1.gif",
    category: gamesCategory.rocket.value,
    isCommon: true,
    rules: `${BUCKET_URL}/rocket-common/rules.html`,
    production: false
  },
  [gameIdInfo.bombBlastMultiplayer]: {
    id: gameIdInfo.bombBlastMultiplayer,
    name: Translate.gameBombBlastMO,
    image: "/images/games/common-bombBlast-v1.gif",
    category: gamesCategory.bomb.value,
    isCommon: true,
    rules: `${BUCKET_URL}/bomb-common/rules.html`,
    production: false
  },
  [gameIdInfo.jungleRunMultiplayer]: {
    id: gameIdInfo.jungleRunMultiplayer,
    name: Translate.gameBookOfCrash,
    image: "/images/games/bookOfCrash.gif",
    category: gamesCategory.bomb.value,
    isCommon: true,
    rules: `${BUCKET_URL}/book-of-crash/rules.html`,
    production: false
  },
  [gameIdInfo.speenWheel]: {
    id: gameIdInfo.speenWheel,
    name: Translate.gameChakkaPatti,
    image: "/images/games/chakkapatti.gif",
    isCommon: true,
    production: false
  }
}
