const API_URL = process.env.REACT_APP_API_URL

export const routes = {
  processGameEvent: API_URL + "/game",
  userTransaction: API_URL + "/transactions",
  userResults: API_URL + "/rounds",
  roundIdComplete: API_URL + "/round_complete",
  getUserInfo: API_URL + "/get_user",
  addCrashPoint: API_URL + "/add_crash_point",
  getUserCrashPoint: API_URL + "/crash_points",
  userChat: API_URL + "/chat",
  bigWins: API_URL + "/big_wins",
  getChakkapattiMultipliers: API_URL + "/get_combination_multiplier",
  getProvablyFairResult: API_URL + "/get_winner_multiplier",
  updateJak47Mode: API_URL + "/players"
}
