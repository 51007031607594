import React from "react"
import "./style.css"

const LoaderV1 = () => (
  <div className="loader-default">
    <div className="wrapper">
      <div className="preloader">
        <img src="/images/ce-logo.png" alt="" className="logo" />
        <div className="loader1"></div>
        <div className="loader2"></div>
      </div>
    </div>
  </div>
)

export default LoaderV1
