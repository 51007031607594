const data = {
  en: "TR",
  user: "Kullanıcı",
  amount: "Miktar",
  autoCashout: "Otomatik Cashout",
  autoCashoutTitle: "Otomatik Cashout ayarları",
  autoPlay: "Otomatik oynatma",
  autoPlayTitle: "Otomatik Oyun Ayarları",
  autoPlayStart: "AutopLay'ı başlatın",
  autoPlayRoundText: "Autobet sayısınızı seçin",
  balance: "Denge",
  blastedText: "Patlamış",
  bombBlastPreStartText1: "Bomba kaydı",
  bombBlastPreStartText2: "Her zaman patlayabilir",
  betTypeManual: "Bahis",
  betTypeAuto: "Oto",
  betNow: "Bahse girerim",
  betOnNextRound: "Bir sonraki turda bahis yapın",
  createdAt: "Düştü",
  cancel: "İptal etmek",
  cashedOut: "Parasız",
  cashOut: "Param bitti",
  crashedText: "çökmüş",
  close: "Kapalı",
  counter: "Tezgah",
  details: "Detaylar",
  disable: "Devre dışı bırakmak",
  effectiveNextRound: "Bir sonraki turdan geçerli",
  enterStake: "Paylaşın Girin",
  errorBettingDisabled: "Bahis devre dışı bırakıldı, Admin ile iletişime geçin",
  gameBombBlast: "Bomba patlaması",
  gameRocketBlast: "Roket patlaması",
  hashGame: "Oyun",
  hash: "Doğramak",
  id: "İD",
  lastWin: "Son galibiyet",
  maxStake: "Maksimum kazık",
  minStake: "Min Pay",
  nextRoundIn: "Sonraki tur",
  off: "KAPALI",
  recentResults: "Son Sonuçlar",
  rocketBlastPreText1: "Kalkmaya hazır",
  roundGame: "Oyun",
  rules: "Tüzük",
  salt: "Tuz",
  stopIf: "Durdur",
  transactions: "İşlemler",
  type: "Tip",
  update: "Güncelleme",
  value: "Değer",
  waitForNext: "Sonraki Bekle",
  warnInvalidStake: "Geçersiz Kazık",
  warnAutoPlayInvalidStake: "Otomatik Oyun: Geçersiz Pay",
  win: "KAZANÇ",
  confirmText: "Lütfen çevrimiçi casino oyunları oynamak için yasal yaşta olduğunuzu onaylayın.",
  yes: "Evet, 18 yaşın üzerindeyim",
  no: "Hayır değilim"
}

export default data
