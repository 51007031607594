import React, { useContext, useEffect, useState } from "react"
import Gamelist from "./Gamelist"
import { gameConfig, gamesCategory } from "../../config/game.config"
import { AiOutlineSearch } from "react-icons/ai"
import { isMobileOnly } from "react-device-detect"
import "../../assest/style/lobby/_main.scss"
import { AuthContext } from "../Auth"
import Left from "./Left"

const totalAssets = 1
let loadedAssets = 0

/**
 * Renders the Home component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.queryParams - The query parameters.
 * @returns {JSX.Element} The rendered Home component.
 */
const Home = (props) => {
  const { queryParams } = props
  const { setLoading } = useContext(AuthContext)
  const [gameInfo, setGameInfo] = useState(null)
  const [activeCategory, setCategory] = useState(gamesCategory.all.value)

  const banner = isMobileOnly ? "banner-mobile-v1.gif" : "banner-desktop.gif"

  const onAssetLoad = () => {
    loadedAssets += 1
    if (loadedAssets == totalAssets) setTimeout(() => setLoading(false), 5000)
  }

  useEffect(() => {
    if (queryParams?.game && gameConfig[queryParams.game]) {
      setGameInfo(queryParams.game)
    } else {
      setGameInfo(null)
    }
  }, [queryParams])

  const onCategoryChange = (category) => () => {
    setCategory(category)
  }

  return (
    <>
      {/* { window.innerWidth < 575 && <Left /> } */}
      <div className="game_wrap w-100" id="gameId">
        <div className="d-flex justify-content-around">
          <div className="banner-one">
            <img src={`/images/${banner}`} onLoad={onAssetLoad} />
          </div>
        </div>
        <div className="category-list">
          {/* <div className='search-icon category-item'>
                        <AiOutlineSearch />
                    </div> */}
          {Object.values(gamesCategory).map((category) => {
            const activeClass = activeCategory == category.value ? "active" : ""
            return (
              <div key={`category-${category.value}`} className={`category-item ${activeClass}`} onClick={onCategoryChange(category.value)}>
                {category.icon && <img src={category.icon} className={`category-icon ${category.value == "all" ? "all" : ""}`} />}
                {category.label}
              </div>
            )
          })}
        </div>
        <Gamelist category={activeCategory} />
      </div>
    </>
  )
}

export default Home
