const data = {
  en: "NL",
  user: "Gebruiker",
  amount: "Hoeveelheid",
  autoCashout: "Auto Cashout",
  autoCashoutTitle: "Auto Cashout -instellingen",
  autoPlay: "Automatisch afspelen",
  autoPlayTitle: "Autoplay -instellingen",
  autoPlayStart: "Start autoplay",
  autoPlayRoundText: "Kies uw aantal Autobet",
  balance: "Evenwicht",
  blastedText: "Gestraald",
  bombBlastPreStartText1: "Bomb gleed weg",
  bombBlastPreStartText2: "Kan op elk moment ontploffen",
  betTypeManual: "Inzet",
  betTypeAuto: "Auto",
  betNow: "Wed nu",
  betOnNextRound: "Wed op de volgende ronde",
  createdAt: "Stortte op",
  cancel: "Annuleren",
  cashedOut: "Uitbetaald",
  cashOut: "Uitbetalen",
  crashedText: "neerstort",
  close: "Dichtbij",
  counter: "Balie",
  details: "Details",
  disable: "Uitzetten",
  effectiveNextRound: "Effectief vanaf de volgende ronde",
  enterStake: "Voer het belang in",
  errorBettingDisabled: "Wedden uitgeschakeld, neem contact op met admin",
  gameBombBlast: "BOMST BLAD",
  gameRocketBlast: "Rocket Blast",
  hashGame: "Game Hash",
  hash: "Hash",
  id: "ID kaart",
  lastWin: "Laatste overwinning",
  maxStake: "Max Stake",
  minStake: "Min Stake",
  nextRoundIn: "Volgende ronde in",
  off: "UIT",
  recentResults: "Recente resultaten",
  rocketBlastPreText1: "Klaar om op te stijgen",
  roundGame: "Spelronde",
  rules: "Reglement",
  salt: "Zout",
  stopIf: "Stop als",
  transactions: "Transacties",
  type: "Type",
  update: "Update",
  value: "Waarde",
  waitForNext: "Wacht op de volgende",
  warnInvalidStake: "Ongeldig belang",
  warnAutoPlayInvalidStake: "Autoplay: ongeldig belang",
  win: "WINNEN",
  confirmText: "Bevestig dat u een wettelijke leeftijd hebt om online casinospellen te spelen.",
  yes: "Ja, ik ben ouder dan 18",
  no: "Nee dat ben ik niet"
}

export default data
